
import React from "react"
import Paper from "@material-ui/core/Paper";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import classnames from "classnames";

interface IProps {
  image?: JSX.Element,
  imageSrc?: string,
  elevation?: number,
  imageClass?: string,
  wrapperClass?: string,
  children: JSX.Element
}


const Card = (props: IProps) => {
  const {
    image,
    imageSrc,
    children
  } = props
  
  const useStyles = makeStyles(() =>
    createStyles({
      paper: {
        height: '100%',
        overflow: 'hidden'
      }
    }),
  );

  const classes = useStyles();

  return (
      <Paper 
        square={false} 
        elevation={props.elevation ? props.elevation : 1}
        className={classnames(classes.paper, [props.wrapperClass || ''])}>
        {imageSrc && (
          <img className={props.imageClass} src={imageSrc} alt=""/>
        )}
        {image && (
          image
        )}
        <div>
          {children}
        </div>
      </Paper>
  )
}

export default Card

