
import React from "react"
import Grid from "@material-ui/core/Grid";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";

interface IProps {
  list: (JSX.Element | null)[];
  justify?: 'center' | 'flex-end' | 'flex-start' | 'space-around' | 'space-between' | 'space-evenly';
  spacing?:  0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | undefined;
  wrapperClassName?: string;
  cardsPerRow?: 1 | 2 | 3 | 4;
  spaceTop?: boolean;
  spaceBottom?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    spaceBottom: {
      paddingBottom: '54px',
      marginBottom: 0
    },
    spaceTop: {
      paddingTop: '54px',
      marginTop: 0
    }
  })
)

const Cards = (props: IProps) => {
  const { list } = props
  const classes = useStyles();

  const getCardsPerRowProps = (cards: 1 | 2 | 3 | 4 ): {
    xs: 12,
    sm: 6 | 12,
    md: 3 | 4 | 6 | 12,
  } => ({
    xs: 12, 
    sm: cards && cards === 1 ? 12 : 6,
    md: cards ? (12/cards as 3 | 4 | 6 | 12) : 4,
  })
  
  return (
      <Grid 
        container 
        spacing={props.spacing || 4} 
        justifyContent={props.justify || 'flex-start'}
        className={
          classnames(
            props.wrapperClassName, {
              [classes.spaceBottom]: props.spaceBottom, 
              [classes.spaceTop]: props.spaceTop 
            }
          )}
      >
        {list.map((item, i) => (
          item && (
            <Grid item {...getCardsPerRowProps(props.cardsPerRow || 3)} key={i}>
              {item}
            </Grid>
            )
          )
        )}
      </Grid>
  )
}

export default Cards