import { ThemeOptions } from '@material-ui/core'
import { createTheme as createMuiTheme } from '@material-ui/core/styles'
import { PaletteOptions } from '@material-ui/core/styles/createPalette'
import hexToRgba from 'hex-to-rgba'

export interface FontsOptionsConfig {
  default?: string
  300?: string
  500?: string
  700?: string
}

export interface FontsOptions {
  primary?: FontsOptionsConfig
}

export interface ThemesOptions extends ThemeOptions {
  fonts?: FontsOptions
}

const merge = require('deepmerge')

const palette = {
  background: {
    default: '#fff'
  },
  primary: {
    main: '#233353',
    light: '#E7E7E3'
  },
  secondary: {
    main: '#EAB54D',
    dark: '#DD723F',
    contrastText: '#4B999C'
  },
  text: {
    primary: '#242937',
    secondary: '#636463'
  }
}

export const fonts: FontsOptions = {
  primary: {
    default: 'MuseoSansCyrl-500',
    300: 'MuseoSansCyrl-300',
    500: 'MuseoSansCyrl-500',
    700: 'MuseoSansCyrl-700'
  }
}

const getPalette = (paletteOptions: PaletteOptions) => {
  return merge.all([palette, paletteOptions || {}])
}

const getFonts = (fontsOptions: FontsOptions) => {
  return merge.all([fonts, fontsOptions || {}])
}

const getThemeWithCustomPalette = (
  paletteNewOptions: PaletteOptions,
  newFonts: FontsOptions
): ThemesOptions => {
  const paletteOptions = getPalette(paletteNewOptions)
  const fontsOptions = getFonts(newFonts)

  return {
    overrides: {
      MuiCssBaseline: {
        '@global': {
          html: {
            color: paletteOptions.text?.primary
          }
        }
      },
      MuiContainer: {
        maxWidthLg: {
          maxWidth: '1200px!important'
        }
      },
      MuiAppBar: {
        root: {
          marginBottom: 24
        }
      },
      MuiLink: {
        root: {
          color: paletteOptions.secondary?.['main']
        }
      },
      MuiButton: {
        root: {
          backgroundColor: paletteOptions.primary?.['main'],
          color: '#FFF',
          fontSize: 16,
          cursor: 'pointer',
          outline: 0,
          borderRadius: 4,
          textTransform: 'none',
          fontFamily: fontsOptions?.primary?.[700],
          height: '48px',
          '&:hover': {
            color: '#FFF',
            backgroundColor: paletteOptions.primary?.['main']
          },
          '&:disabled': {
            opacity: 0.8,
            color: '#FFF'
          }
        }
      },
      MuiPaper: {
        rounded: {
          boxShadow: '0px 6px 34px rgba(36, 41, 55, 0.08)',
          borderRadius: '10px'
        },
        elevation1: {
          boxShadow: '0px 6px 34px rgba(36, 41, 55, 0.08)'
        }
      },
      MuiTypography: {
        root: {
          textOverflow: 'ellipsis',
          overflow: 'hidden'
        }
      },
      MuiDivider: {
        root: {
          backgroundColor: hexToRgba(
            String(paletteOptions.text?.['secondary']),
            0.1
          )
        },
        light: {
          backgroundColor: hexToRgba(
            String(paletteOptions.text?.['secondary']),
            0.1
          )
        }
      },
      MuiInputBase: {
        root: {
          paddingBottom: 0
        }
      }
      // MuiFormControl: {
      //   'root': {
      //     marginBottom: 30
      //   }
      // }
    },
    typography: {
      fontFamily: [fontsOptions?.primary?.[300], 'sans-serif'].join(','),
      htmlFontSize: 16,
      h1: {
        fontSize: 38,
        textTransform: 'uppercase',
        paddingTop: 24,
        paddingBottom: 24,
        fontWeight: 'normal',
        lineHeight: 'normal',
        fontFamily: fontsOptions?.primary?.[700]
      },
      h2: {
        fontSize: 28,
        textTransform: 'uppercase',
        textAlign: 'center',
        paddingTop: 24,
        paddingBottom: 24,
        fontWeight: 'normal',
        lineHeight: 'normal',
        fontFamily: fontsOptions?.primary?.[700]
      },
      h3: {
        fontSize: 24,
        textTransform: 'uppercase',
        textAlign: 'center',
        paddingTop: 24,
        paddingBottom: 24,
        fontWeight: 'normal',
        lineHeight: 'normal',
        fontFamily: fontsOptions?.primary?.[700]
      },
      h4: {
        fontSize: 20,
        paddingTop: 24,
        paddingBottom: 24,
        fontWeight: 'normal',
        lineHeight: 'normal',
        fontFamily: fontsOptions?.primary?.[700]
      },
      h5: {
        fontSize: 16,
        marginTop: 24,
        paddingBottom: 24,
        fontWeight: 'normal',
        lineHeight: 'normal',
        fontFamily: fontsOptions?.primary?.[700]
      },
      h6: {
        fontSize: 14,
        paddingBottom: 24,
        fontWeight: 'normal',
        lineHeight: 'normal',
        fontFamily: fontsOptions?.primary?.[700]
      },
      subtitle1: {
        fontSize: 16,
        color: paletteOptions.text?.['secondary'],
        paddingBottom: 16,
        fontWeight: 'normal',
        lineHeight: 'normal',
        fontFamily: fontsOptions?.primary?.[500]
      },
      subtitle2: {
        fontSize: 14,
        color: paletteOptions.text?.['secondary'],
        paddingBottom: 16,
        fontWeight: 'normal',
        lineHeight: 'normal',
        fontFamily: fontsOptions?.primary?.[500]
      },
      button: {
        // button/links
        fontSize: 16,
        color: paletteOptions.secondary?.['main'],
        fontFamily: fontsOptions?.primary?.[300]
      },
      body1: {
        fontSize: 14,
        paddingBottom: 16,
        lineHeight: 'normal',
        fontFamily: fontsOptions?.primary?.[300]
      },
      body2: {
        fontSize: 12,
        paddingBottom: 16,
        fontWeight: 'normal',
        lineHeight: 'normal',
        fontFamily: fontsOptions?.primary?.[700]
      },
      caption: {
        // labels
        fontSize: 16,
        display: 'inline-block',
        fontWeight: 'normal',
        lineHeight: 'normal',
        fontFamily: fontsOptions?.primary?.[700]
      }
    },
    palette: paletteOptions,
    fonts: fontsOptions
  }
}

export const getTheme = (
  data?: ThemeOptions,
  fontsOptions?: FontsOptions
): ThemesOptions => {
  const initialTheme = merge.all([
    getThemeWithCustomPalette(data?.palette || {}, fontsOptions || {}),
    data || {}
  ])
  return initialTheme
}

export const createTheme = (theme: ThemesOptions) => {
  return createMuiTheme(theme)
}
