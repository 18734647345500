import React from 'react'
import { withStyles, Theme, createStyles } from '@material-ui/core/styles'
import Tab from '@material-ui/core/Tab'

interface StyledTabProps {
  label: string,
  value: string
}

const AntTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      minWidth: 72,
      '&:not(:last-child)': {
        marginRight: theme.spacing(4),
      },
      color: theme.palette.text.secondary,
      ...theme.typography.caption,
      '&$selected': {
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    selected: {},
  }),
)((props: StyledTabProps) => <Tab disableRipple {...props} />)

export default AntTab