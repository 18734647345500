import React from 'react'
import { Helmet } from 'react-helmet'
import { ThemeProvider, ThemeProviderProps } from '@material-ui/core/styles'

const ThemeWithFont: React.FC<ThemeProviderProps & {noExternalFonts?: boolean, swapFonts?: boolean}> = ({children, theme, noExternalFonts, swapFonts, ...rest}) => {
  return (
    <ThemeProvider theme={theme} {...rest}>
      {!noExternalFonts && (
        <Helmet>
          <link href={`https://resources.imcoursery.com/fonts/SabonCyr/stylesheet${swapFonts ? '-swap' : ''}.css`} rel='stylesheet' type='text/css'/>
          <link href={`https://resources.imcoursery.com/fonts/MuseoSans/stylesheet${swapFonts ? '-swap' : ''}.css`} rel='stylesheet' type='text/css'/>
        </Helmet>
      )}
      {children}
    </ThemeProvider>
  )
}

export default ThemeWithFont