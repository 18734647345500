import React from "react"
import InputAdornment from "@material-ui/core/InputAdornment"
import TextField, { TextFieldProps } from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"

import {Theme, makeStyles, createStyles } from '@material-ui/core'

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    label: {
      alignSelf: 'flex-start'
    }
  }),
)

const TextInput = ({
  leftIcon,
  rightIcon,
  label,
  errorMessage,
  error,
  ...props
}: TextFieldProps & {
  leftIcon?: JSX.Element
  rightIcon?: JSX.Element
  errorMessage?: string
}) => {
  const classes = useStyles()
  return (
    <React.Fragment>
      {!!label && <Typography variant="body1" className={classes.label}>{label}</Typography>}
      <TextField
        variant="outlined"
        error={error}
        InputProps={{
          startAdornment: leftIcon ? (
            <InputAdornment position="start">{leftIcon}</InputAdornment>
          ) : undefined,
          endAdornment: rightIcon ? (
            <InputAdornment position="end">{rightIcon}</InputAdornment>
          ) : undefined,
        }}
        helperText={!!error && errorMessage}
        FormHelperTextProps={{
          error
        }}
        {...props}
      />
    </React.Fragment>
  )
}

export default TextInput