import React, { useState } from "react"
import Typography from "@material-ui/core/Typography"
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles'
import classNames from "classnames"

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    label: {
      alignSelf: 'flex-start',
      fontSize: '12px',
      textTransform: 'uppercase',
      paddingBottom: '10px',
      color: _theme.palette.text.primary
    },
    inputContainer: {
      position: 'relative',
      width: '100%',
      marginBottom: '30px'
    },
    textField: {
      outline: '0!important',
      width: '100%',
      border: '1px solid rgba(122, 131, 159, 0.4)',
      height: '48px',
      maxHeight: '48px',
      borderRadius: '4px',
      padding: '.875rem 1rem',
    },
    errorContainer: {
      border: '1px solid #FF4242',
    },
    errorBadge: {
      width: '16px',
      height: '16px',
      borderRadius: '50%',
      border: '2px solid #FF4242',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      right: '16px',
      '& span': {
          width: '6px',
          height: '6px',
          display: 'block',
          borderRadius: '50%',
          background: '#FF4242'
      }
    },
    error: {
      position: 'absolute',
      top: '48px',
      right: 0,
      background: '#fff',
      color: '#FF4242',
      minHeight: '37px',
      fontSize: '14px',
      marginTop: '7px',
      textAlign: 'left',
      width: '100%',
      borderRadius: '4px',
      padding: '14px 16px',
      boxShadow: '0px 7px 64px rgba(0, 0, 0, 0.07)',
      zIndex: 999,
      '& .error-arrow': {
        width: '12px',
        height: '12px',
        background: '#fff',
        borderRadius: '4px',
        position: 'absolute',
        right: '18px',
        top: '-5px',
        transform: 'rotate(45deg)',
      }
    }
  }),
)

export const FormInput = ({
  label,
  showError,
  errorMessage,
  ...props
}: any) => {
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false)

  const classes = useStyles()
  return (
    <React.Fragment>
      {!!label && <Typography variant="subtitle1" className={classes.label}>{label}</Typography>}
      <div className={classes.inputContainer}>
        <input
          className={classNames(classes.textField, {[classes.errorContainer]: showError})}
          variant="outlined"
          {...props}
        />

        {showError ? (
          <React.Fragment>
            <div 
              className={classNames(classes.errorBadge, 'error-badge')} 
              onMouseOver={() => setShowErrorMessage(true)}
              onMouseLeave={() => setShowErrorMessage(false)}
              data-testid="error-badge"
            >
              <span></span>
            </div>
            {showErrorMessage && (
              <div data-testid="error-container" className={classNames(classes.error, 'error-container')}>
                <div className="error-arrow"></div>
                <div>
                  {errorMessage}
                </div>
              </div>
            )}
          </React.Fragment>
          ) : null}
      </div>
      
    </React.Fragment>
  )
}

export default FormInput