import React from "react"
import MuiButton, { ButtonProps } from "@material-ui/core/Button"
import { createStyles, makeStyles, Theme, withStyles } from "@material-ui/core/styles"
import { ThemesOptions } from "../services/material-theme"
import hexToRgba from "hex-to-rgba"

interface Props extends ButtonProps {
  children: JSX.Element | string
}

const Button = (props?: Props) => {   
  const isColorSecondary = props?.color && props.color === 'secondary';
  const paletteVariant: 'primary' | 'secondary' = isColorSecondary ? 'secondary' : 'primary'

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        backgroundColor: theme.palette[paletteVariant]?.['main'],
        '&:hover': {
          backgroundColor: theme.palette[paletteVariant]?.['main'],
          boxShadow: `0px 2px 14px ${hexToRgba(theme.palette[paletteVariant]?.['main'], 0.35)}`
        },
      },
      outlined: {
        borderColor: theme.palette[paletteVariant]?.['main'],
        color: theme.palette[paletteVariant]?.['main'],
        '&:hover': {
          color: theme.palette[paletteVariant]?.['main'],
        },
        '&:disabled': {
          color: theme.palette[paletteVariant]?.['main'],
          borderColor: theme.palette[paletteVariant]?.['main']
        }
      }
    }),
  );

  const classes = useStyles();
  const isOutlined = props?.variant && props.variant === 'outlined'

 return (
    <MuiButton {...props} className={!isOutlined ? classes.root : classes.outlined}>{props?.children || ''}</MuiButton>
  )
}

export default withStyles((theme: ThemesOptions) => ({
  root: {
    backgroundColor: theme?.palette?.primary?.['main'] || 'inherit',
    color: '#FFF',
    fontSize: 16,
    cursor: 'pointer',
    outline: 0,
    borderRadius: 4,
    textTransform: 'none',
    fontFamily: theme.fonts?.primary?.['700'] || 'inherit',
    '-webkit-transition': '-webkit-transform 0.3s',
    transition: 'outline 0.3s linear',
    height: '48px',
    '&:hover': {
      color: '#FFF',
      backgroundColor: theme?.palette?.primary?.['main'],
      boxShadow: `0px 2px 14px ${hexToRgba(theme?.palette?.primary?.['main'], 0.35)}`
    },
    '&:disabled': {
      opacity: 0.8,
      color: '#FFF',
    }
  },
  outlined: {
    backgroundColor: 'transparent',
    borderColor: theme?.palette?.primary?.['main'],
    color: theme?.palette?.primary?.['main'],
    borderWidth: 2,
    '&:hover': {
      borderWidth: 3,
      color: theme?.palette?.primary?.['main'],
      backgroundColor: 'transparent',
      boxShadow: 'none'
    },
    '&:disabled': {
      opacity: 0.54,
      borderColor: theme?.palette?.primary?.['main'],
      borderWidth: 2,
      color: theme?.palette?.primary?.['main'],
      backgroundColor: 'transparent',
    }
  }
}))(Button)