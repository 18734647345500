import React from 'react'
import Modal, { ModalProps } from '@material-ui/core/Modal'
import Fade from '@material-ui/core/Fade'
import Button from '@material-ui/core/Button'
import Backdrop from '@material-ui/core/Backdrop'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import { Close } from '@material-ui/icons'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      minHeight: '500px',
      minWidth: '760px',
      '&:focus': {
        outline: 'none',
      },
    },
  }),
)
export default ({
  children,
  open,
  handleClose,
  ...rest
}: ModalProps & { handleClose: () => void }) => {
  const classes = useStyles()
  return (
    <Modal
      closeAfterTransition
      BackdropComponent={Backdrop}
      className={classes.modal}
      BackdropProps={{
        timeout: 500,
      }}
      open={open}
      onClose={handleClose}
      {...rest}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <Button onClick={handleClose}>
            <Close />
          </Button>
          {children}
        </div>
      </Fade>
    </Modal>
  )
}
