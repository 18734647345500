
import React from "react"
import PageContainer from "@material-ui/core/Container";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import classnames from "classnames";

interface IProps {
  children: JSX.Element,
  spaceBottom?: boolean,
  spaceTop?: boolean,
}


const Container = (props: IProps) => {
  const {
    children
  } = props
  
  const useStyles = makeStyles(() =>
    createStyles({
      spaceTop: {
        paddingTop: '54px',
        marginTop: 0
      },
      spaceBottom: {
        paddingBottom: '54px',
        marginBottom: 0
      }
    }),
  );

  const classes = useStyles();

  return (
    <PageContainer 
      className={
        classnames({
          [classes.spaceBottom]: props.spaceBottom, 
          [classes.spaceTop]: props.spaceTop
        })
      }
    >
      {children}
    </PageContainer>
  )
}

export default Container

