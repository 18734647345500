import React from 'react';
import classNames from "classnames";
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import InfoIcon from '@material-ui/icons/Info';
import { ThemesOptions } from '../services/material-theme';

export interface IProps {
  message?: string | undefined,
  wrapperRef?: React.RefObject<HTMLDivElement> | undefined
}

const useStyles = makeStyles((theme: ThemesOptions) =>
  createStyles({
    errorWrapper: {
        background: `rgba(255, 84, 84, 0.08)`,
        borderRadius: '4px',
        padding: '20px 30px',
        margin: '0 0 30px 0',
        display: 'flex',
        alignItems: 'center',
        color: '#FF5454',
        animation: 'appear 0.5s',
        lineHeight: 'normal',
        '& svg': {
            fontSize: '20px'
        },
        '& a': {
          color: theme?.palette?.secondary?.['main'] || 'inherit',
          textDecoration: 'none'
        },
        ["@media screen and (max-width: 768px)"]: {
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            background: '#fff',
            padding: '35px 25px 40px',
            boxShadow: '0px 0px 46px rgba(65, 64, 66, 0.12)',
            margin: 0,
            zIndex: '9999'
        }
    },
    message: {
        color: '#FF5454',
        marginLeft: '10px',
        fontFamily: theme?.fonts?.primary?.['500'],
        paddingTop: '5px',
        fontSize: '16px',
    },
    "@keyframes appear": {
        "0%": {
            opacity: 0
        },
        "100%": {
            opacity: 1
        }
    }
  }),
);

const ErrorMessage = (props: IProps) => {
  const classes = useStyles();

  return (
    props.message ? (
      <div ref={props.wrapperRef} className={classNames("error-message", classes.errorWrapper)}>
        <InfoIcon />
        <Box component="div"  dangerouslySetInnerHTML={{ __html: props.message }} className={classes.message}/>
      </div>
    ) : <React.Fragment/>
  )
}

export default ErrorMessage