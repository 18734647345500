import { withStyles, Theme } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'

const AntTabs = withStyles((theme: Theme) => ({
  root: {
    borderBottom: '1px solid #e8e8e8',
    width: 'fit-content',
  },
  indicator: {
    backgroundColor: theme.palette.secondary.dark,
  },
}))(Tabs)

export default AntTabs