
import React from "react"
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import { findCodeByLanguage, hasFlag } from "../services/flags";
import { FcGlobe } from 'react-icons/fc';
import { ThemesOptions } from "../services/material-theme";


interface IProps {
  language: string
}

const useStyles = makeStyles((theme: ThemesOptions) =>
  createStyles({
    flagWrapper: {
      maxHeight: 20,
      display: 'inline-block'
    },
    flag: {
      margin: 0,
      width: 20,
      height: 20,
      objectFit: 'cover',
      borderRadius: '50%',
      boxShadow: '0.2px 0.2px 0.1px rgba(36, 41, 55, 0.8)',
    },
    languagesWrapper: {
      width: 'auto',
      display: 'flex',
      flexDirection: 'row'
    },
    language: {
      fontSize: 18,
      paddingTop: 4,
      paddingBottom: 0,
      fontFamily: theme?.fonts?.primary?.[500],
      display: 'inline-block',
      margin: '0 10px'
    },
    worldIcon: {
      width: '20px',
      height: '20px',
    }
  }),
);

const Language = (props: IProps) => {
  const { language } = props

  const classes = useStyles();

  const flagIcon = (language: string) => {
    const code = findCodeByLanguage(language)?.toUpperCase() || ''
    // used web links from https://www.npmjs.com/package/country-flag-icons
    if(hasFlag(code))
      return <img alt={language} className={classes.flag} src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${code}.svg`}/>
    else return <FcGlobe className={classes.worldIcon}/>
  }

  
  return (
    <Grid className={classes.languagesWrapper} container alignItems="center">
      <Box component="div" className={classes.flagWrapper}>
        {flagIcon(language)}
      </Box>
      <Typography variant="body1" className={classes.language}>{language}</Typography>
    </Grid>
  )
}

export default Language

